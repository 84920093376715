//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/tools/PageHeaderCustom.vue';
import RadioComponent from '@/components/common/RadioComponent.vue';
import { DEFAULT_PORTAL_SETTING, DEFAULT_PAGE_SETTING, PAGE_STATE } from '@/enum';
import UploadPhoto from '@/components/spec/UploadPhoto.vue';

export default {
  components: {
    PageHeader,
    RadioComponent,
    UploadPhoto
  },

  data() {
    return {
      form: this.$form.createForm(this, { name: 'compose-project' }),
      thumbnail: {
        url: '',
        alt: ''
      },
      pageTemplate: 'CATALOG',
      timer: undefined,
      loading: false
    };
  },

  computed: {
    ...mapGetters('user', ['userInfo']),
    formItemLayout() {
      return {
        labelCol: { sm: { span: 8 }, lg: { span: 4 }, xl: { span: 4 } },
        wrapperCol: { sm: { span: 16 }, lg: { span: 16 }, xl: { span: 16 } }
      };
    },
    formTailLayout() {
      return {
        labelCol: { sm: { span: 8 }, lg: { span: 4 }, xl: { span: 4 } },
        wrapperCol: {
          sm: { span: 16, offset: 8 },
          lg: { span: 16, offset: 4 },
          xl: { span: 16, offset: 4 }
        }
      };
    },
    pageSetting() {
      return this.pageTemplate === 'CATALOG' ? DEFAULT_PAGE_SETTING : DEFAULT_PORTAL_SETTING;
    },
    baseSubDomain() {
      return process.env.VUE_APP_UNIVOICE_SUBDOMAIN;
    },
    folderNameS3() {
      return `${process.env.VUE_APP_S3_PROJECT_THUMBNAIL}`;
    }
  },
  methods: {
    ...mapActions('project', ['addProject']),
    ...mapActions('page', ['fetchPagesByProject']),

    createProject() {
      this.form.validateFields(async (err, values) => {
        try {
          this.loading = true;
          if (!err) {
            const payload = {
              name: values.name,
              sub_domain: values.sub_domain,
              description: values.description,
              thumbnail: this.thumbnail,
              page: {
                name: values.name,
                template: this.pageTemplate,
                setting_options: this.pageSetting,
                state: PAGE_STATE.PRIMARY,
                thumbnail: this.thumbnail
              }
            };
            const data = await this.$s.api.project.createProject(this.userInfo.company_id, payload);
            this.$notification.success({
              message: this.$tc('Created Project'),
              duration: 3
            });
            this.addProject({ payload: data });
            this.fetchPagesByProject(data.id);
            this.$router.push(`/projects`);
          }
        } catch (error) {
          console.log(error);
          this.$notification.error({
            message: error.message,
            duration: 3
          });
        } finally {
          this.loading = false;
        }
      });
    },
    validateExistSubDomain(rule, value, callback) {
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        try {
          if (!value) return callback();
          value = value
            .trim()
            .replace(/\s+/g, '-')
            .replace(/[&\/\\#,+()$~%.'":*?<>_[\]{}]/g, '')
            .toLowerCase();
          this.form.setFieldsValue({ sub_domain: value });
          const isExist = await this.$s.api.project.checkExistSubDomain(value);
          if (isExist) {
            callback(this.$tc('This sub domain is already exist!'));
          } else {
            callback();
          }
        } catch (error) {
          callback(this.$t(error.message));
        }
        callback();
      }, 700);
    },
    changeThumbnail(thumbnail) {
      this.thumbnail = thumbnail;
    }
  }
};
